<template>
    <div>
        <div class="filter_tips">
           已驳回商品请修改
        </div>

        <div class="list">
            <list-item v-for="item in 3" type="reject"/>
        </div>
 
    </div>
</template>

<script>
import listItem from './listItem.vue'
export default {
  components: { listItem },
    name: "RejectedList",
    data() {
        return {

        }
    },
    
}

</script>
<style lang="less" scoped>
 
.filter_tips {
    padding: 15px 18px 0;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #DC393C;
    line-height: 1;
}

</style>