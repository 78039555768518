import { render, staticRenderFns } from "./selectSchoolWihtSearch.vue?vue&type=template&id=58cce1cc&scoped=true&"
import script from "./selectSchoolWihtSearch.vue?vue&type=script&lang=js&"
export * from "./selectSchoolWihtSearch.vue?vue&type=script&lang=js&"
import style0 from "./selectSchoolWihtSearch.vue?vue&type=style&index=0&id=58cce1cc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58cce1cc",
  null
  
)

export default component.exports