<template>
    <div>
        <div class="filter">
            <van-field placeholder="请选择学校" :value="schoolName" readonly right-icon="arrow-down" @click="$refs.selectSchool.showPop()" />
            <van-field placeholder="请选择商品" :value="goodsName" readonly right-icon="arrow-down" @click="$refs.selectGoods.showPop()"/>
            <van-field placeholder="请选择类型" :value="typeName" readonly right-icon="arrow-down" @click="showType = true"/>
        </div>


        <div class="filter_tips">
            共找到<span>3</span>个商品
        </div>

        <div class="list">
           <list-item v-for="item in 3" />
        </div>

        <select-school ref="selectSchool" @select="onSchoolSelect" /> <!-- 选择学校 -->
        <select-goods ref="selectGoods" @select="onGoodsSelect"/>

        <van-popup v-model="showType" position="bottom" round>
            <van-picker
                title="选择类型"
                show-toolbar
                :columns="typeList"
                @confirm="onTypeConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
import selectSchool from './selectSchoolWihtSearch.vue'
import ListItem from './listItem.vue'
import SelectGoods from './selectGoodsWithSearch.vue'
export default {
    components: { selectSchool, SelectGoods, ListItem },
    name: "PublishedList",
    data() {
        return {
            schoolName: '',
            schoolId: '',

            goodsName: '',
            goodsId: '',

            showType: false,
            typeId: '',
            typeList: [{text: '全部', value: '' }, {text: '限时预定', value: '1' }, {text: '随时购买', value: '2' }],
        }
    },
    computed: {
        typeName() {
            return this.typeList.find(e => this.typeId === e.value).text;
        }
    },
    methods: {
        onSchoolSelect(item) {
            console.log(item)
            this.schoolName = '和平小学' + item;
            this.schoolId = 'xxx'
        },
        onGoodsSelect(item) {
            console.log(item)
            this.goodsName = '长沙市一中（夏季套装）男女同款';
            this.goodsId = 'xxx'
        },
        onTypeConfirm(item) {
            this.typeId = item.value;
            this.showType = false
        }
    }
}

</script>
<style lang="less" scoped>
::v-deep .van-field__control {
    font-size: 12px;
}
::v-deep .van-field__right-icon .van-icon {
    font-size: 12px !important;
}

.van-cell {
    padding: 8px 30px;
    &::after { left: 0; right: 0;}
}
.filter {
    margin-top: 1px;
}


.filter_tips {
    padding: 15px 18px 0;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 1;
    span {
         color: #212121;
    }
}


</style>
