<template>
  <van-popup v-model="show" position="bottom" round>
    <div class="head van-hairline--bottom">
      <div>
        学校
      </div>

    </div>
    <van-search v-model="keyword" placeholder="请输入搜索关键词"/>

    <div class="list">
      <div class="item" v-for="(item,index) in schoolList" :key="index" @click="selectSchool(item)">
        <div class="van-ellipsis">
          {{item.name}}{{item.keyCode}}
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import Vue from "vue";

export default {
  name: "SelectSchool",
  data() {
    return {
      keyword: '',
      show: false,
      schoolList:[],
      timeout:null,
    }
  },
  watch:{
    keyword(val){
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.handleChangeName(val)
      }, 100)
    }
  },
  methods: {
    handleChangeName(val) {
      //查询学校数据
      this.$http.get(`/mall/grade/querySchoolByDeptId`, {params: {name: val, deptId: Vue.prototype.deptId}}, {
        emulateJSON: true
      }).then(res => {
        this.schoolList = res.data;
      });
    },
    showPop() {
      this.keyword = ''
      this.show = true
    },
    selectSchool(item) {
      this.$emit('select', item)
      this.show = false
    }
  }
}

</script>
<style lang="less" scoped>
.head {
  display: flex;
  padding: 17px 28px;

  div {

    font-size: 12px;
    color: #666;
    width: 100%;
    text-align: center;


  }
}

.list {
  height: 60vh;
  overflow-y: auto;

  .item {
    display: flex;
    padding: 10px 28px;

    div {
      font-size: 12px;
      color: #666;
      width: 100%;
      text-align: center;
      color: #000;


    }
  }
}
</style>
