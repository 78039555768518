<template>
 <div class="item" @click="$router.push('/shelves/edit')">
    <div class="goodsInfo">
        <div class="goodsName van-multi-ellipsis--l2">
            <span class="ys">预售</span>长沙市一中（夏季套装）男女同款
        </div>

        <div class="tagList">
            <div class="tag">随时购买</div>
            <!-- <div class="tag">限时预定</div> -->
        </div>

        <div class="handle">
            <div class="price">
                <span>¥</span>
                <span>55.00</span>
            </div>
        </div>
    </div>

    <img src="@/assets/images/6.jpg" class="goodsImg" alt="">

    <img v-if="type === 'reject'" src="@/assets/images/shelves/reject_tips.png" class="reject_tips" />
</div>
</template>

<script>
export default {
    name: "ListItem",
    props: {
        type: {
            default: 'normal',
            type: String
        }
    }
}

</script>
<style lang="less" scoped>
 .item {
    padding: 10px 13px 15px;
    background: #FFFFFF;
    border-radius: 8px;
    margin: 11px 18px;

    display: flex;
    position: relative;
    .goodsImg {
        height: 83px;
        width: 99px;
        min-width: 99px;
        margin-left: 26px;
    }

    .reject_tips {
        height: 50px;
        width: 50px;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}


.goodsInfo {
    flex: 1; width: 0;
    padding: 3px 0 0;
    .goodsName {
        font-size: 13px;
        color: #333333;
        line-height: 18px;

        .ys {
            width: 32px;
            background: linear-gradient(264deg, #f58130, #ef2514);
            border-radius: 2px;

            font-size: 12px;
            font-weight: 500;
            color: #F4F4F4;
            line-height: 12px;
            text-align: center;
            padding: 2px 0;
            display: inline-block;
            margin-right: 3px;

        }
    }


    .tagList {
        padding: 4px 0 10px 0;
        font-size: 0;
        .tag {
            border: 1px solid #D53A26;
            border-radius: 2px;
            padding: 3px 7px;
            font-size: 10px;
            font-weight: 400;
            color: #D53A26;
            line-height: 10px;
            display: inline-block;
            margin-right: 11px;
            &.plain {
                padding-left: 0;
                padding-right: 0;
                color: #666;
                border: none;
            }
        }
    }

    .handle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 11px;
        .price {
            color: #FF0000;
            font-weight: 500;
            span:first-child {
                font-size: 10px;
                margin-right: 3px;
                font-weight: bold;
            }

            span:last-child {
                font-size: 13px;
                font-weight: bold;
            }
        }

    }
}
</style>
